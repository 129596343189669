@tailwind base;
@tailwind components;
@tailwind utilities;

/** Fonts **/
@font-face {
  font-display: swap;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  src: local("Cairo"), local("Cairo-Regular"),
  url("../fonts/cairo.woff2")
  format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
  U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
  U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-display: swap;
  font-family: "icons";
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("../fonts/icons.woff")
  format("woff");
}

.icon-block {
  padding: 0 15px;
}

.icon-block .material-icons {
	font-size: inherit;
}

.progress
{
	height: 0.25rem !important;
}

/* Disable materialize-css junk. */
span.thumb { display: none; }
input[type=range] { border: 0; }

#contributionComment,
#contributionName
{
	padding-left: 0.5rem;
}


/* Material icons packages with icomoon */
[class^="icon-"], [class*=" icon-"]
{
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	font-size: 1.5rem;
	line-height: 1.25;
	margin-top: -0.15rem;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.leftarrow:before { content: "\003c" }
.icon-report_problem:before { content: "\e900"; }
.icon-chat:before { content: "\e901"; }
.icon-chat_bubble_outline:before { content: "\e902"; }
.icon-email:before { content: "\e903"; }
.icon-access_time:before { content: "\e904"; }
.icon-attach_money:before { content: "\e905"; }
.icon-download:before { content: "\e90e"; }
.icon-security:before { content: "\e906"; }
.icon-person_outline:before { content: "\e907"; }
.icon-bookmark_border:before { content: "\e908"; }
.icon-code:before { content: "\e909"; }
.icon-face:before { content: "\e90a"; }
.icon-favorite_border:before { content: "\e90b"; }
.icon-info:before { content: "\e90c"; }
.icon-translate:before { content: "\e90d"; }
.icon-label:before { content: "\e90f"; }
.icon-share:before { content: "\e910"; }
.icon-help:before { content: "\e911"; }
.icon-nightlife:before { content: "\e912"; }
.icon-downarrow:before { content: "\02ec"; }
.icon-bitcoin:before { content: "\e913"; }
.icon-dollar:before { content: "\e905"; }
.icon-usd:before { content: "\e905"; }
.icon-bitcoin:before { content: "\e913"; }
.icon-btc:before { content: "\e913"; }
.icon-bch:before { content: "\e913"; }

.down-arrow:before { content: "▼"; }
.up-arrow:before { content: "▲"; }

/** Animations **/
@keyframes horizontalBackgroundMovement
{
	from { transform: translate(-4rem, 0); }
	to { transform: translate(-8rem, 0); }
}

.waves-container
{
	position: relative;
	display: block;
	height: 4rem;
	width: 4rem;
	overflow: hidden;
	z-index: 3;

	border-radius: 999rem;
	margin: 0;
	padding: 0rem;
	border: 0;
	background-color: transparent;
	box-shadow: inset 0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.31), 0 0 0 1rem rgb(255, 255, 255);
}

.waves {
	content: '';
	position: absolute;
	display: block;
	height: 4rem;
	width: 100vw;
	z-index: 1;

	background-image: url('../img/water.png');
	background-repeat: repeat-x;
	background-size: contain;
	background-position: 0 4rem;

	animation-name: horizontalBackgroundMovement;
	animation-timing-function: linear;
	animation-duration: 6s;
	animation-iteration-count: infinite;
	will-change: transform;
}

.waves {
	background-image: url(../img/water.png);
	background-repeat: repeat-x;
	background-size: contain;
	background-position: 0 4rem;
	animation-name: horizontalBackgroundMovement;
	animation-timing-function: linear;
	animation-duration: 6s;
	animation-iteration-count: infinite;
	will-change: transform;
}

/**/
.clouds
{
	background-color: white;
	background-image: url(../img/clouds.png);
	background-size: contain;
	background-repeat: repeat-x;
	background-position: top;
}

.grass
{
	background-image: url(../img/grass.png);
	background-size: contain;
	background-repeat: repeat-x;
	background-position: bottom;
}

.background
{
	background: linear-gradient(180deg, rgba(256,256,256,0) 0%, rgba(256,256,256,0) 500px, rgba(216,255,214,1) 500px, rgba(216,255,214,1) 100%);
	margin-top: -2rem;
	padding-bottom: 2rem;
}

#header > h1 > span
{
	font-size: 4.75rem;
}

#header > h1 > img
{
	display: inline-block;
	position: relative;
	top: 0.75rem;
	height: 5.25rem;
}

#header > p
{
	font-family: 'Cairo';
	font-size: 1.33rem;
	padding: 0rem 1.5rem;
}

/* Site content */
main
{
	max-width: 72rem;
}

.progress
{
	height: 0.25rem;
	background-color: lightgrey;
}

#campaignProgressBar
{
	height: 0.25rem;
	background-color: #32cd8d;
}

#campaignContributionBar
{
	height: 0.25rem;
	background-color: khaki;
	transition: left 0.75s ease-in-out;
}

#pendingRevocationBar
{
	height: 0.25rem;
	background-color: #f14300d6;
	transition: left 0.75s ease-in-out;
}

/* Translate button */
#languageSelector ul .btn-floating
{
	transition: all 0.25s ease-in;
	opacity: 0;
	transform: scale(0.4) translateY(40px) translateX(0px);
}
#languageSelector.active ul .btn-floating
{
	opacity: 1 !important;
	transform: scale(1) translateY(0px) translateX(0px);
}

#currentLanguage
{
	width: auto;
	height: 4rem;
	line-height: 4rem;
	padding: 1rem;

	font-size: 1.15rem;
	vertical-align: middle;

	text-shadow: 0px 0px 7px white,
	             0px 0px 5px white,
	             0px 0px 3px white,
	             0px 0px 2px white,
	             0px 0px 1px white;
}

.d-none {
	display: none !important;
}

.v-hidden {
	visibility: hidden !important;
	opacity: 0 !important;
}

.cta-btn {
	color: #000000d9;
	font-weight: 600;
	letter-spacing: .05rem;
	border: none;
	width: 100%;
	padding: 15px 45px;
	text-align: center;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	cursor: pointer;
	border: 1px solid transparent;
	box-shadow: 0 6px 14px rgb(0 0 0 / 10%);
}

.primary-btn {
	background: #33fd5f;
}

.primary-btn:hover {
	background: #33fd5f82;
}

.primary-btn.gradient {
	border:none;
	background: linear-gradient(180deg,#33fd5f,#18b116)
}

.primary-btn.gradient:hover {
	background: #33fd5f 35.42%
}

.secondary-btn {
	background: white;
	border: 1px solid #33fd5f;
}

.secondary-btn.gradient {
	border:none;
	background: linear-gradient(180deg,#9effa86b 50%,#78fc57bf);
}

.secondary-btn:hover.gradient {
	background: #9effa86b 50%
}

main article {
	display: grid;
	grid-template-areas:
			"hero"
			"title"
			"sidebar"
			"content";
	grid-template-columns: 1fr;
	grid-template-rows: auto;
}

@media screen and (max-width: 600px) {
	.sidebar {
		margin-top: 0rem !important;
		padding-left: 0rem !important;
	}
}

.total-raised small, .total-donors small {
	font-size: .85rem;
	color: #606060;
}

.total-raised {
	font-size: 1.25rem;
}

.total-donors {
	display: none;
}

.progress {
	margin: 0;
}

.donate-page {
	display: grid;
	grid-gap: 1rem;
	grid-template-areas:
			"main"
			"sidebar";
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	padding-bottom: 1rem;
	padding-top: 1rem;
}

@media screen and (min-width: 800px) {
	main article {
		grid-template-areas:
		"title title title title"
		"hero hero hero sidebar"
		"content content . sidebar"
		"content content . sidebar";
		grid-template-columns: 4fr 4fr 0fr 4.5fr;
	}

	.donate-panel .total-raised {
		order: -1;
		font-size: 1.25rem;
		margin-top: .25rem !important;
		margin-bottom: .25rem !important;
	}

	.progress {
		margin-bottom: .25rem !important;
	}

	.total-donors {
		display: block;
		margin-bottom: 1rem !important;
	}


	main article #campaignTitle {
		font-size: 2rem;
		margin-bottom: 2rem;
	}

	.sidebar { 
		order: -1; 
		height: 100%;
		width: 100%;
		position: relative;
		margin-top: 0rem !important;
	}

	.sidebar .donate-panel {
		position: sticky;
		top: 1.5rem;
		left: 1.5rem;
		width: 100%;

    transition: box-shadow .25s, -webkit-box-shadow .25s;
    padding: .25rem 2rem 2rem 1rem;
    margin: 0.5rem 0 1rem 0;
    border-radius: 2px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
	}
}

.peer:not(:placeholder-shown):not(:focus) ~ .floating-label {
	display: none;
}


.btn-xs {
	height: 1.5rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	min-height: 1.5rem;
	font-size: .75rem;
}

.btn-ghost, .badge-ghost {
	border-width: 1px;
	border-color: transparent;
	background-color: transparent;
	color: currentColor;
}

.btn {
	display: inline-flex;
	flex-shrink: 0;
	cursor: pointer;
	user-select: none;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	border-color: transparent;
	text-align: center;
	height: 3rem;
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: .875rem;
	line-height: 1.25rem;
	line-height: 1em;
	min-height: 3rem;
	font-weight: 600;
	text-transform: uppercase;
	text-decoration-line: none;
}

.badge {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 1.25rem;
	font-size: .875rem;
	line-height: 1.25rem;
	width: fit-content;
	padding-left: 0.563rem;
	padding-right: 0.563rem;
	border-width: 1px;
}

.badge-sm {
	height: 1rem;
	font-size: .75rem;
	line-height: 1rem;
	padding-left: 0.438rem;
	padding-right: 0.438rem;
}